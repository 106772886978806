import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, FormLabel, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPaw,
  faCalendar,
  faStethoscope,
  faWeightScale,
  faFileCircleQuestion,
  faSyringe,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

const RegisterVisitModal = ({ show, owner, pet, handleClose, ...props }) => {
  function makeOwnersList() {
    const result = [];
    props.petData.forEach((pet) => {
      // TODO: also show owner name, to resolve same name ambiguity
      result.push(<option value={pet.petID}>{pet.petName}</option>);
    });
    return result;
  }
  return (
    <Modal size="lg" as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Καταχώρηση επίσκεψης</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/visits/register" method="post">
          <Row>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="petID">
                <FontAwesomeIcon icon={faPaw} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              {pet && (
                <select name="petID" id="petID" defaultValue={pet.petID}>
                  {makeOwnersList()}
                </select>
              )}
              {!pet && (
                <select name="petID" id="petID">
                  {makeOwnersList()}
                </select>
              )}
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="timestamp">
                <FontAwesomeIcon icon={faCalendar} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="datetime-local"
                name="timestamp"
                id="timestamp"
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="reason">
                <FontAwesomeIcon icon={faFileCircleQuestion} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="reason"
                placeholder="Αίτιο προσκόμισης"
                id="reason"
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="weight">
                <FontAwesomeIcon icon={faWeightScale} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="number"
                step="any"
                name="weight"
                placeholder="Βάρος ζώου"
                id="weight"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="findings">
                <FontAwesomeIcon icon={faStethoscope} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="findings"
                placeholder="Κλινικά Ευρήματα"
                id="findings"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="treatment">
                <FontAwesomeIcon icon={faSyringe} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="treatment"
                placeholder="Θεραπεία"
                id="treatment"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="pending">
                <FontAwesomeIcon icon={faClockRotateLeft} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="pending"
                placeholder="Εκκρεμότητες"
                id="pending"
              />
            </Col>
            <input type="submit" value="Καταχώρηση" />
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {
    petData: state.petData,
  };
};

export default connect(mapStateToProps)(RegisterVisitModal);
