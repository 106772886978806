import React from "react";
import { connect } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";

const DeletePetModal = ({ show, pet, handleClose, ...props }) => {
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          Είστε σίγουροι ότι θέλετε να διαγράψετε το κατοικίδιο "{pet.petName}";
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/pets/delete" method="post">
          <input
            type="text"
            name="petID"
            id="petID"
            value={pet.petID}
            style={{ display: "none" }}
            required
          />
          <input type="submit" value="ΜΟΝΙΜΗ διαγραφή κατοικιδίου" />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(DeletePetModal);
