import React from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdatePetModal from "./UpdatePetModal";
import DeletePetModal from "./DeletePetModal";
import {
  faDog,
  faCat,
  faCrow,
  faCarrot,
  faMars,
  faVenus,
  faTrashCan,
  faPenToSquare,
  faCommentDots,
  faCakeCandles,
  faPaw,
  faWeightScale,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";

const getAnimalIcon = function (species) {
  if (species === "Dog") {
    return <FontAwesomeIcon icon={faDog} />;
  } else if (species === "Cat") {
    return <FontAwesomeIcon icon={faCat} />;
  } else if (species === "Bird") {
    return <FontAwesomeIcon icon={faCrow} />;
  } else if (species === "Bunny") {
    return <FontAwesomeIcon icon={faCarrot} />;
  } else {
    return "[" + species + "]";
  }
};

const getPetAge = function (birthday) {
  var today = new Date();
  var bday = new Date(birthday);

  const diffTime = Math.abs(today - bday);
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const years = Math.floor(diffDays / 365.25);
  diffDays = diffDays - years * 365.25;
  const months = Math.floor(diffDays / 30.4);

  var str = "";
  if (years === 1) str = str + years + " έτους ";
  else if (years > 1) str = str + years + " ετών ";
  if (months === 1) str = str + months + " μηνός ";
  else if (months > 1) str = str + months + " μηνών ";

  if (years === 0 && months === 0) str = diffDays + " ημερών ";

  return str;
};

const PetInfo = ({
  selected,
  openModalUpdatePet,
  setOpenModalUpdatePet,
  openModalDeletePet,
  setOpenModalDeletePet,
  attribute = "petID",
  ...props
}) => {
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const pet = props.data.filter((line) => line["petID"] === selected)[0];
  return (
    <>
      {setOpenModalUpdatePet && (
        <Row className="justify-content-start d-flex">
          <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
            <Button
              className="me-2"
              onClick={() => setOpenModalUpdatePet(true)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />{" "}Επεξεργασία Ζώου
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModalDeletePet(true)}>
              <FontAwesomeIcon icon={faTrashCan} />{" "}Διαγραφή Ζώου
            </Button>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light">
              <h5 className="mb-0">
                {" "}
                {getAnimalIcon(pet.species)} {pet.petName}{" "}
              </h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4} xs={12}>
                  <b><FontAwesomeIcon icon={faPaw} /></b>{" "}{pet.breed}
                  <br />
                  <b><FontAwesomeIcon icon={faCakeCandles} /></b>{" "}{getPetAge(pet.birthday)} (
                  {new Intl.DateTimeFormat("el-GR", dateOptions).format(
                    new Date(pet.birthday)
                  )}
                  )
                  <br />
                  {pet.gender ? (
                    <FontAwesomeIcon icon={faMars} />
                  ) : (
                    <FontAwesomeIcon icon={faVenus} />
                  )}{" "}
                  {pet.neutered ? "(Στειρωμένο)" : "(Αστείρωτο)"}
                  <br />
                  <b><FontAwesomeIcon icon={faMicrochip} /></b>{" "}{pet.chipNum === "" ? "-" : pet.chipNum}
                  <br />
                  <b><FontAwesomeIcon icon={faWeightScale} /></b>{" "}
                  {props.visitData.filter((line) => line["petID"] === selected)[0]
                    ? props.visitData.filter(
                      (line) => line["petID"] === selected
                    )[0].weight
                    : "-"}{" "}
                  kg
                </Col>
                <Col md={8} xs={12}>
                  <b>Μέτρα πρόληψης</b>: {pet.preventiveMeas}
                  <br />
                  <b>Συνθήκες Διαβίωσης</b>: {pet.livingCond}
                  <br />
                  {attribute !== "petID" && (
                    <>
                      <b><FontAwesomeIcon icon={faCommentDots} /></b>{" "}{pet.petComment}
                    </>
                  )}
                  {attribute === "petID" && (
                    <>
                      <Form action="/pets/savecomment" method="post">
                        <input
                          type="text"
                          name="petID"
                          id="petID"
                          value={pet.petID}
                          style={{ display: "none" }}
                          required
                        />
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            name="petComment"
                            id="petComment"
                            defaultValue={pet.petComment}
                            required
                          />
                          <Button variant="primary" type="submit">
                            Αποθήκευση
                          </Button>
                        </InputGroup>
                      </Form>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <UpdatePetModal
        show={openModalUpdatePet}
        pet={pet}
        handleClose={() => setOpenModalUpdatePet(false)}
      />
      <DeletePetModal
        show={openModalDeletePet}
        pet={pet}
        handleClose={() => setOpenModalDeletePet(false)}
      />
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    data: state.petData,
    visitData: state.visitData,
  };
};

export default connect(mapStateToProps)(PetInfo);
