import { createStore } from "redux";

let initialState = {
  ownerData: [],
  petData: [],
  visitData: [],
  userName: "",
  searchTarget: null
};

function updateState(state = initialState, action) {
  if (action.type === "SET_OWNER_DATA") {
    return { ...state, ownerData: action.payload };
  } else if (action.type === "SET_PET_DATA") {
    return { ...state, petData: action.payload };
  } else if (action.type === "SET_VISIT_DATA") {
    return { ...state, visitData: action.payload };
  } else if (action.type === "SET_USER_NAME") {
    return { ...state, userName: action.payload };
  } else if (action.type === "SET_SEARCH_TARGET") {
    return { ...state, searchTarget: action.payload };
  } else {
    return state;
  }
}

const store = createStore(updateState);

export default store;
