import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import VisitsTable from "../components/Visits/VisitsTable";

import store from "../store";

const Visits = (props) => {
  const [selectedVisit, setSelectedVisit] = useState(null);

  useEffect(() => {
    if (props.searchTarget) setSelectedVisit(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
  });

  return (
    <VisitsTable
      selectedPet={null}
      selectedVisit={selectedVisit}
      setSelectedVisit={setSelectedVisit}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    visitData: state.visitData,
    searchTarget: state.searchTarget,
  };
};

export default connect(mapStateToProps)(Visits);
