import React, { useState } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import RegisterPetModal from "./RegisterPetModal.js";
import UpdatePetModal from "./UpdatePetModal";
import DeletePetModal from "./DeletePetModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPenToSquare,
  faTrashCan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const PetsTable = ({
  selectedOwner,
  selectedPet,
  setSelectedPet,
  ...props
}) => {
  const [openModalRegisterPet, setOpenModalRegisterPet] = useState(false);
  const [openModalUpdatePet, setOpenModalUpdatePet] = useState(false);
  const [openModalDeletePet, setOpenModalDeletePet] = useState(false);
  const navigate = useNavigate();

  function showPets() {
    const res = [];
    props.petData
      .filter((line) => line.ownerID === selectedOwner || !selectedOwner)
      .forEach((element) => {
        res.push(
          <tr key={"pet" + element.petID}>
            <td
              className="cursorpointer"
              onClick={() => navigate("/pets/" + element.petID)}
            >
              {element.petName}
            </td>
            <td
              className="cursorpointer"
              onClick={() => navigate("/pets/" + element.petID)}
            >
              {element.species}
            </td>
            {!selectedOwner && (
              <td
                className="cursorpointer"
                onClick={() => navigate("/pets/" + element.petID)}
              >
                {element.surname} {element.name}
              </td>
            )}
            <td className="text-right">
              <Button onClick={() => {
                setSelectedPet(element.petID);
                setOpenModalUpdatePet(true);
              }}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </Button>
              {" "}
              <Button className="btn-danger" onClick={() => {
                setSelectedPet(element.petID);
                setOpenModalDeletePet(true);
              }}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </td>
          </tr>
        );
      });
    return res;
  }

  return (
    <>
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Button onClick={() => setOpenModalRegisterPet(true)}>
            <FontAwesomeIcon icon={faPlus} />{" "}Καταχώρηση νέου ζώου
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Card border="light" className="shadow-sm">
            {selectedOwner && (
              <Card.Header className="border-bottom border-light">
                <h5 className="mb-0">
                  Τα κατοικίδια του{" "}
                  {
                    props.ownerData.filter(
                      (line) => line.ownerID === selectedOwner
                    )[0].name
                  }{" "}
                  {
                    props.ownerData.filter(
                      (line) => line.ownerID === selectedOwner
                    )[0].surname
                  }
                </h5>
              </Card.Header>
            )}
            <Card.Body>
              <table className="table-centered rounded w-100">
                <thead>
                  <tr>
                    <th className="border-bottom">Όνομα ζώου</th>
                    <th className="border-bottom">Είδος</th>
                    {!selectedOwner && (
                      <th className="border-bottom">Όνομα ιδιοκτήτη</th>
                    )}

                    <th className="border-bottom"></th>
                  </tr>
                </thead>
                <tbody>{showPets()}</tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <RegisterPetModal
        show={openModalRegisterPet}
        owner={
          props.ownerData.filter((line) => line.ownerID === selectedOwner)[0]
        }
        handleClose={() => setOpenModalRegisterPet(false)}
      />

      {selectedPet && (
        <>
          <UpdatePetModal
            show={openModalUpdatePet}
            pet={props.petData.filter((line) => line.petID === selectedPet)[0]}
            handleClose={() => setOpenModalUpdatePet(false)}
          />
          <DeletePetModal
            show={openModalDeletePet}
            pet={props.petData.filter((line) => line.petID === selectedPet)[0]}
            handleClose={() => setOpenModalDeletePet(false)}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
    petData: state.petData,
  };
};

export default connect(mapStateToProps)(PetsTable);
