import React, { useState } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import RegisterVisitModal from "./RegisterVisitModal.js";
import UpdateVisitModal from "./UpdateVisitModal";
import DeleteVisitModal from "./DeleteVisitModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPenToSquare,
  faTrashCan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const VisitsTable = ({
  selectedOwner,
  selectedPet,
  setSelectedPet,
  selectedVisit,
  setSelectedVisit,
  ...props
}) => {
  const [openModalRegisterVisit, setOpenModalRegisterVisit] = useState(false);
  const [openModalUpdateVisit, setOpenModalUpdateVisit] = useState(false);
  const [openModalDeleteVisit, setOpenModalDeleteVisit] = useState(false);
  const navigate = useNavigate();

  function showVisits() {
    const res = [];
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "Europe/Athens",
    };
    props.visitData
      .filter((line) => line.petID === selectedPet || !selectedPet)
      .filter((line) => line.ownerID === selectedOwner || !selectedOwner)
      .forEach((element) => {
        res.push(
          <tr key={"visit" + element.petID}>
            <td
              className="cursorpointer"
              onClick={() => {
                navigate("/visits/" + element.visitID);

                if (selectedOwner) setSelectedPet(element.petID);
              }}
            >
              {new Intl.DateTimeFormat("el-GR", options).format(
                new Date(element.timestamp)
              )}
            </td>
            <td>{element.reason}</td>
            {!selectedPet && (
              <>
                <td
                  className="cursorpointer"
                  onClick={() => {
                    navigate("/visits/" + element.visitID);
                    if (selectedOwner) setSelectedPet(element.petID);
                  }}
                >
                  {element.petName}
                </td>
                <td
                  className="cursorpointer"
                  onClick={() => {
                    navigate("/visits/" + element.visitID);
                    if (selectedOwner) setSelectedPet(element.petID);
                  }}
                >
                  {element.species}
                </td>
                {!selectedOwner && (
                  <td
                    className="cursorpointer"
                    onClick={() => {
                      navigate("/visits/" + element.visitID);
                      if (selectedOwner) setSelectedPet(element.petID);
                    }}
                  >
                    {element.surname} {element.name}
                  </td>
                )}
              </>
            )}
            <td className="text-right">
            <Button onClick={() => {
                setSelectedVisit(element.visitID);
                setOpenModalUpdateVisit(true);
              }}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </Button>
              {" "}
              <Button className="btn-danger" onClick={() => {
                setSelectedVisit(element.visitID);
                setOpenModalDeleteVisit(true);
              }}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </td>
          </tr>
        );
      });
    return res;
  }

  return (
        <>
          <Row className="justify-content-center d-block">
            <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
              <Button onClick={() => setOpenModalRegisterVisit(true)}>
                <FontAwesomeIcon icon={faPlus} />{" "}Καταχώρηση νέας επίσκεψης
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center d-block">
            <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
              <Card border="light" className="shadow-sm">
                {selectedPet && (
                  <Card.Header className="border-bottom border-light">
                    <h5 className="mb-0">
                      Οι επισκέψεις{" "}
                      {(props.petData.filter(
                        (line) => line.petID === selectedPet
                      )[0].gender
                        ? "του "
                        : "της ") +
                        " " +
                        props.petData.filter(
                          (line) => line.petID === selectedPet
                        )[0].petName}
                    </h5>
                  </Card.Header>
                )}
                {!selectedPet && selectedOwner && (
                  <Card.Header className="border-bottom border-light">
                    <h5 className="mb-0">
                      Οι επισκέψεις του{" "}
                      {
                        props.ownerData.filter(
                          (line) => line.ownerID === selectedOwner
                        )[0].name
                      }{" "}
                      {
                        props.ownerData.filter(
                          (line) => line.ownerID === selectedOwner
                        )[0].surname
                      }
                    </h5>
                  </Card.Header>
                )}
                <Card.Body>
                  <table className="table-centered rounded w-100">
                    <thead>
                      <tr>
                        <th className="border-bottom">Ημερομηνία</th>
                        <th className="border-bottom">Αίτιο Προσκόμισης</th>
                        {!selectedPet && (
                          <>
                            <th className="border-bottom">Όνομα ζώου</th>
                            <th className="border-bottom">Είδος</th>
                            {!selectedOwner && (
                              <th className="border-bottom">Όνομα ιδιοκτήτη</th>
                            )}
                          </>
                        )}
                        <th className="border-bottom"></th>
                      </tr>
                    </thead>
                    <tbody>{showVisits()}</tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <RegisterVisitModal
            show={openModalRegisterVisit}
            pet={
              props.petData.filter((line) => line.ownerID === selectedPet)[0]
            }
            handleClose={() => setOpenModalRegisterVisit(false)}
          />
          {selectedVisit && (
            <>
              <UpdateVisitModal
                show={openModalUpdateVisit}
                visit={props.visitData.filter((line) => line.visitID === selectedVisit)[0]}
                handleClose={() => setOpenModalUpdateVisit(false)}
              />
              <DeleteVisitModal
                show={openModalDeleteVisit}
                visit={props.visitData.filter((line) => line.visitID === selectedVisit)[0]}
                handleClose={() => setOpenModalDeleteVisit(false)}
              />
            </>
          )}
        </>
  );
};
const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
    petData: state.petData,
    visitData: state.visitData,
  };
};

export default connect(mapStateToProps)(VisitsTable);
