import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "../routes";

// pages
import Calendar from "./Calendar";
import Owners from "./Owners";
import Owner from "./Owner";
import Pet from "./Pet";
import Pets from "./Pets";
import Visit from "./Visit";
import Visits from "./Visits";
import Login from "./Login";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader.js";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={loaded ? false : true} /> <Component {...Component} />{" "}
    </>
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <>
      <Preloader show={loaded ? false : true} />
      <Sidebar />

      <main className="content">
        <Navbar />
        <Component {...Component} />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};

export default () => (
  <Routes>
    {/* pages */}
    <Route
      exact
      path={AppRoutes.Calendar.path}
      element={<RouteWithSidebar component={Calendar} />}
    />
    <Route
      exact
      path={AppRoutes.Owners.path + "/:ownerid"}
      element={<RouteWithSidebar component={Owner} />}
    />
    <Route
      exact
      path={AppRoutes.Owners.path}
      element={<RouteWithSidebar component={Owners} />}
    />
    <Route
      exact
      path={AppRoutes.Pets.path + "/:petid"}
      element={<RouteWithSidebar component={Pet} />}
    />
    <Route
      exact
      path={AppRoutes.Pets.path}
      element={<RouteWithSidebar component={Pets} />}
    />
    <Route
      exact
      path={AppRoutes.Visits.path + "/:visitid"}
      element={<RouteWithSidebar component={Visit} />}
    />
    <Route
      exact
      path={AppRoutes.Visits.path}
      element={<RouteWithSidebar component={Visits} />}
    />
    {/* <Route
      exact
      path={AppRoutes.Overview.path}
      element={<RouteWithLoader component={Overview} />}
    /> */}
    <Route
      exact
      path={AppRoutes.Login.path}
      element={<RouteWithLoader component={Login} />}
    />
    {/* <Route path="*" element={<RouteWithLoader component={NotFoundPage} />} /> */}
    {/* <Navigate to={AppRoutes.NotFound.path} /> */}
  </Routes>
);
