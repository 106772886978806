import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import OwnersTable from "../components/Owners/OwnersTable";

import store from "../store";

const Owners = (props) => {
  const [selectedOwner, setSelectedOwner] = useState(null);

  useEffect(() => {
    if(props.searchTarget) setSelectedOwner(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
  })

  return (
    <>
      <OwnersTable
        selectedOwner={selectedOwner}
        setSelectedOwner={setSelectedOwner}
      />
    </>
  );
};
const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
    searchTarget: state.searchTarget,
  };
};

export default connect(mapStateToProps)(Owners);
