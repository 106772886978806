import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Col,
  Row,
  Nav,
  Navbar,
  Dropdown,
  Container,
  Form,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import store from "../store";

const NavigationBar = (props) => {
  const [search, setSearch] = useState("");
  const [filterlist, setFilterlist] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // if (props.userName === "") {
      fetch("/checksession")
        .then((res) => res.json())
        .then((res) => {
          if (res === null) navigate("/login");
          store.dispatch({
            type: "SET_USER_NAME",
            payload: res,
          });
        });
    // }
    // if (props.ownerData.length === 0) {
      fetch("/owners")
        .then((res) => res.json())
        .then((data) =>
          store.dispatch({
            type: "SET_OWNER_DATA",
            payload: data,
          })
        );
    // }
    // if (props.petData.length === 0) {
      fetch("/pets")
        .then((res) => res.json())
        .then((data) =>
          store.dispatch({
            type: "SET_PET_DATA",
            payload: data,
          })
        );
    // }
    // if (props.visitData.length === 0) {
      fetch("/visits")
        .then((res) => res.json())
        .then((data) =>
          store.dispatch({
            type: "SET_VISIT_DATA",
            payload: data,
          })
        );
    // }
  },[location.pathname]);

  useEffect(() => {
    const searchItems = [
      ...props.ownerData,
      ...props.petData,
      ...props.visitData,
    ];
    const filteredList = searchItems.filter((item) => {
      let all_str =
        `${item.petName} ${item.name} ${item.surname} ${item.timestamp} ${item.reason}`.toLowerCase();
      // return all_str.indexOf(search) > -1; // View All When Search Empty
      return all_str.indexOf(search) > -1 && search;
    });
    setFilterlist(filteredList);
  }, [search, props]);

  function onKeyUpHandler(e) {
    if (e.key === "Enter") {
      var ref;
      var id;
      if (filterlist[0].visitID) {
        ref = "visits";
        id = filterlist[0].visitID;
      } else if (filterlist[0].petID) {
        ref = "pets";
        id = filterlist[0].petID;
      } else {
        ref = "owners";
        id = filterlist[0].ownerID;
      }
      searchClick(ref, id);
    } else {
      setSearch(e.target.value.toLowerCase());
    }
  }

  function clearFilter() {
    const e = document.getElementById("searchBar");
    setSearch("");
    e.value = "";
    e.click(); // force element to lose focus
  }

  const SearchItem = (props) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Europe/Athens",
    };
    const {
      name,
      surname,
      phone,
      petName,
      timestamp,
      reason,
      petID,
      visitID,
      ownerID,
    } = props;
    const date = timestamp
      ? new Intl.DateTimeFormat("en-GB", options).format(new Date(timestamp))
      : null;
    const type = visitID ? "Visit" : petID ? "Pet" : "Owner";
    const displayName = timestamp
      ? date
      : petName
      ? petName
      : name + " " + surname;
    const extraInfo = timestamp
      ? petName + " - " + reason
      : petName
      ? name
      : phone;
    var ref;
    var id;

    if (visitID) {
      id = visitID;
      ref = "visits/" + id;
    } else if (petID) {
      id = petID;
      ref = "pets/" + id;
    } else {
      id = ownerID;
      ref = "owners/" + id;
    }

    return (
      <ListGroup.Item
        action
        onClick={() => {
          searchClick(ref, id);
          clearFilter();
        }}
        className="border-bottom border-light"
      >
        <Row className="align-items-center">
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{displayName}</h4>
              </div>
              <div className="text-end">
                <small>{type}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{extraInfo}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  function searchClick(ref, id) {
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: id,
    });
    navigate("/" + ref);
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 mb-4">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between  w-100">
          <div className="d-flex align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Item}
                className="text-dark icon-notifications me-lg-3"
              >
                <Form className="navbar-search">
                  <Form.Group id="topbarSearch">
                    <InputGroup className="input-group-merge search-bar">
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        id="searchBar"
                        type="text"
                        placeholder="Search"
                        onKeyUp={onKeyUpHandler}
                        // onBlur={clearFilter}
                        defaultValue={search}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  {filterlist.map((item) => (
                    <SearchItem key={item.id} {...item} />
                  ))}
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}> */}
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {/* {areNotificationsRead ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )} */}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notifications
                  </Nav.Link>

                  {/* 
                  {notifications.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))} */}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* <Image src={Profile3} className="user-avatar md-avatar rounded-circle" /> */}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {props.userName}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2 hidden">
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                  Support
                </Dropdown.Item>

                <Dropdown.Divider /> */}

                <Dropdown.Item className="fw-bold" as="div">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />
                  <form id="logout-form" action="/auth/logout" method="post">
                    <input
                      id="logout"
                      className="fw-bold dropdown-item"
                      type="submit"
                      value="Logout"
                    />
                  </form>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = function (state) {
  return {
    userName: state.userName,
    ownerData: state.ownerData,
    petData: state.petData,
    visitData: state.visitData,
    serachTarget: state.searchTarget,
  };
};

export default connect(mapStateToProps)(NavigationBar);
