import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, FormLabel, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUser,
  faPhone,
  faEnvelope,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const UpdateOwnerModal = ({ show, owner, handleClose, ...props }) => {
  return (
    <Modal size="lg" as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Ενημέρωση ιδιοκτήτη</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/owners/update" method="post">
          <input
            type="text"
            name="id"
            placeholder="Owner's ID"
            id="id"
            value={owner.ownerID}
            style={{ display: "none" }}
            required
          />
          <Row>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="surname">
                <FontAwesomeIcon icon={faUser} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="surname"
                placeholder="Επώνυμο"
                id="surname"
                defaultValue={owner.surname}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="name">
                <FontAwesomeIcon icon={faUser} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="name"
                placeholder="Όνομα"
                id="name"
                defaultValue={owner.name}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="phone">
                <FontAwesomeIcon icon={faPhone} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="phone"
                placeholder="Τηλέφωνο"
                id="phone"
                defaultValue={owner.phone}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="email">
                <FontAwesomeIcon icon={faEnvelope} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="email"
                placeholder="E-mail"
                id="email"
                defaultValue={owner.email}
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="address">
                <FontAwesomeIcon icon={faMapLocationDot} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="address"
                placeholder="Διεύθυνση"
                id="address"
                defaultValue={owner.address}
              />
            </Col>
            <input type="submit" value="Ενημέρωση" />
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(UpdateOwnerModal);
