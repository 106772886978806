import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import OwnerInfo from "../components/Owners/OwnerInfo";
import PetInfo from "../components/Pets/PetInfo";
import VisitInfo from "../components/Visits/VisitInfo";

import VisitsTable from "../components/Visits/VisitsTable";

import BackToTables from "../components/BackToTables";

import store from "../store";


const Pets = (props) => {
  const { petid } = useParams();

  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  
  const [openModalUpdatePet, setOpenModalUpdatePet] = useState(false);
  const [openModalDeletePet, setOpenModalDeletePet] = useState(false);

  useEffect(() => {
    if (props.searchTarget) setSelectedPet(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
    if(props.petData.length !== 0) setSelectedPet(parseInt(petid));
  },[props.petData,petid]);


  return (
    <>
      {selectedPet && (
        <>
          <BackToTables
            setters={[setSelectedPet, setSelectedVisit]}
            backTo={"/pets"}
            flavor="στα ζώα"
          />

          <PetInfo
            selected={selectedPet}
            openModalUpdatePet={openModalUpdatePet}
            setOpenModalUpdatePet={setOpenModalUpdatePet}
            openModalDeletePet={openModalDeletePet}
            setOpenModalDeletePet={setOpenModalDeletePet}
          />

          <OwnerInfo
            data={props.petData}
            attribute="petID"
            selected={selectedPet}
          />

          <VisitsTable
            selectedPet={selectedPet}
            selectedVisit={selectedVisit}
            setSelectedVisit={setSelectedVisit}
          />

          {selectedVisit && (
            <>
              <BackToTables
                setters={[setSelectedVisit]}
                flavor={
                  "στις επισκέψεις " +
                  (props.petData.filter((line) => line.petID === selectedPet)[0]
                    .gender
                    ? "του "
                    : "της ") +
                  props.petData.filter((line) => line.petID === selectedPet)[0]
                    .petName
                }
              />

              <Row className="justify-content-center d-block">
                <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
                  <VisitInfo
                    data={props.visitData}
                    attribute="visitID"
                    selected={selectedVisit}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    petData: state.petData,
    visitData: state.visitData,
    searchTarget: state.searchTarget,

  };
};

export default connect(mapStateToProps)(Pets);
