import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import OwnerInfo from "../components/Owners/OwnerInfo";
import PetInfo from "../components/Pets/PetInfo";
import VisitInfo from "../components/Visits/VisitInfo";

import BackToTables from "../components/BackToTables";

import store from "../store";

const Visits = (props) => {
  const { visitid } = useParams();

  const [selectedVisit, setSelectedVisit] = useState(null);
  const [openModalUpdateVisit, setOpenModalUpdateVisit] = useState(false);
  const [openModalDeleteVisit, setOpenModalDeleteVisit] = useState(false);

  useEffect(() => {
    if (props.searchTarget) setSelectedVisit(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
    if (props.visitData.length !== 0) setSelectedVisit(parseInt(visitid));
  }, [props.visitData, visitid]);

  return (
    <>
      {selectedVisit && (
        <>
          <BackToTables
            setters={[setSelectedVisit]}
            flavor="στις επισκέψεις"
            backTo={"/visits"}
          />

          <VisitInfo
            data={props.visitData}
            attribute="visitID"
            selected={selectedVisit}
            openModalUpdateVisit={openModalUpdateVisit}
            setOpenModalUpdateVisit={setOpenModalUpdateVisit}
            openModalDeleteVisit={openModalDeleteVisit}
            setOpenModalDeleteVisit={setOpenModalDeleteVisit}
          />

          <PetInfo
            selected={
              props.visitData.filter(
                (line) => line.visitID === selectedVisit
              )[0].petID
            }
            attribute="visitID"
          />

          <OwnerInfo
            data={props.visitData}
            attribute="visitID"
            selected={selectedVisit}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    visitData: state.visitData,
    searchTarget: state.searchTarget,
  };
};

export default connect(mapStateToProps)(Visits);
