import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import OwnerInfo from "../components/Owners/OwnerInfo";
import PetInfo from "../components/Pets/PetInfo";
import VisitInfo from "../components/Visits/VisitInfo";

import OwnersTable from "../components/Owners/OwnersTable";
import PetsTable from "../components/Pets/PetsTable";
import VisitsTable from "../components/Visits/VisitsTable";

import BackToTables from "../components/BackToTables";

import store from "../store";

const Owners = (props) => {
  const { ownerid } = useParams();
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [openModalUpdateOwner, setOpenModalUpdateOwner] = useState(false);
  const [openModalDeleteOwner, setOpenModalDeleteOwner] = useState(false);

  const [openModalUpdatePet, setOpenModalUpdatePet] = useState(false);
  const [openModalDeletePet, setOpenModalDeletePet] = useState(false);

  useEffect(() => {
    if (props.searchTarget) setSelectedOwner(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
    if(props.ownerData.length !== 0) setSelectedOwner(parseInt(ownerid));
  },[props.ownerData,ownerid]);

  return (
    <>
      {selectedOwner && (
        <>
          <BackToTables
            setters={[setSelectedOwner, setSelectedPet, setSelectedVisit]}
            backTo={"/owners"}
            flavor="στους ιδιοκτήτες"
          />

          <OwnerInfo
            data={props.ownerData}
            attribute="ownerID"
            selected={selectedOwner}
            openModalUpdateOwner={openModalUpdateOwner}
            setOpenModalUpdateOwner={setOpenModalUpdateOwner}
            openModalDeleteOwner={openModalDeleteOwner}
            setOpenModalDeleteOwner={setOpenModalDeleteOwner}
          />

          <PetsTable
            selectedOwner={selectedOwner}
            selectedPet={selectedPet}
            setSelectedPet={setSelectedPet}
            setOpenModalUpdatePet={setOpenModalUpdatePet}
            setOpenModalDeletePet={setOpenModalDeletePet}
          />

          {selectedPet && (
            <>
              <BackToTables
                setters={[setSelectedPet, setSelectedVisit]}
                flavor={
                  "στα ζώα του/της " +
                  props.ownerData.filter(
                    (line) => line.ownerID === selectedOwner
                  )[0].name
                }
              />
              <PetInfo
                selected={selectedPet}
                openModalUpdatePet={openModalUpdatePet}
                setOpenModalUpdatePet={setOpenModalUpdatePet}
                openModalDeletePet={openModalDeletePet}
                setOpenModalDeletePet={setOpenModalDeletePet}
              />
            </>
          )}

          <VisitsTable
            selectedOwner={selectedOwner}
            selectedPet={selectedPet}
            selectedVisit={selectedVisit}
            setSelectedVisit={setSelectedVisit}
            setSelectedPet={setSelectedPet}
          />

          {selectedVisit && (
            <>
              <BackToTables
                setters={[setSelectedVisit]}
                flavor={
                  "στις επισκέψεις " +
                  (props.petData.filter((line) => line.petID === selectedPet)[0]
                    .gender
                    ? "του "
                    : "της ") +
                  props.petData.filter((line) => line.petID === selectedPet)[0]
                    .petName
                }
              />

              <Row className="justify-content-center d-block">
                <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
                  <VisitInfo
                    data={props.visitData}
                    attribute="visitID"
                    selected={selectedVisit}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
    petData: state.petData,
    visitData: state.visitData,
    searchTarget: state.searchTarget,
  };
};

export default connect(mapStateToProps)(Owners);
