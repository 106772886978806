import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import RegisterOwnerModal from "./RegisterOwnerModal.js";
import UpdateOwnerModal from "./UpdateOwnerModal";
import DeleteOwnerModal from "./DeleteOwnerModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const OwnersTable = ({ selectedOwner, setSelectedOwner, ...props }) => {
  const [openModalRegisterOwner, setOpenModalRegisterOwner] = useState(false);
  const [openModalUpdateOwner, setOpenModalUpdateOwner] = useState(false);
  const [openModalDeleteOwner, setOpenModalDeleteOwner] = useState(false);
  const navigate = useNavigate();

  function showOwners() {
    const res = [];
    props.ownerData.forEach((element) => {
      res.push(
        <tr key={"owner" + element.ownerID}>
          <td
            className="cursorpointer"
            onClick={() => navigate("/owners/" + element.ownerID)}
          >
            {element.surname} {element.name}
          </td>
          <td
            className="cursorpointer"
            onClick={() => navigate("/owners/" + element.ownerID)}
          >
            {element.phone}
          </td>
          <td className="text-right">
          <Button onClick={() => {
                setSelectedOwner(element.ownerID);
                setOpenModalUpdateOwner(true);
              }}>
                <FontAwesomeIcon icon={faPenToSquare} />
              </Button>
              {" "}
              <Button className="btn-danger" onClick={() => {
                setSelectedOwner(element.ownerID);
                setOpenModalDeleteOwner(true);
              }}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
          </td>
        </tr>
      );
    });
    return res;
  }

  return (
    <>
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Button onClick={() => setOpenModalRegisterOwner(true)}>
            <FontAwesomeIcon icon={faPlus} />{" "}Καταχώρηση νέου ιδιοκτήτη
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <table className="table-centered rounded w-100">
                <thead>
                  <tr>
                    <th className="border-bottom">Ονοματεπώνυμο</th>
                    <th className="border-bottom">Τηλέφωνο</th>
                    <th className="border-bottom"></th>
                  </tr>
                </thead>
                <tbody>{showOwners()}</tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <RegisterOwnerModal
        show={openModalRegisterOwner}
        handleClose={() => setOpenModalRegisterOwner(false)}
      />
      {selectedOwner && (
        <>
          <UpdateOwnerModal
            show={openModalUpdateOwner}
            owner={
              props.ownerData.filter(
                (line) => line["ownerID"] === selectedOwner
              )[0]
            }
            handleClose={() => setOpenModalUpdateOwner(false)}
          />
          <DeleteOwnerModal
            show={openModalDeleteOwner}
            owner={
              props.ownerData.filter(
                (line) => line["ownerID"] === selectedOwner
              )[0]
            }
            handleClose={() => setOpenModalDeleteOwner(false)}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
  };
};

export default connect(mapStateToProps)(OwnersTable);
