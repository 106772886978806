import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Row, Col } from "react-bootstrap";
import store from "../store";
import * as d3 from "d3";
import { useD3 } from "../components/UseD3";
import "./App.css";

const App = (props) => {
  const day = 86400000;

  const now = new Date();
  const currDay = now.getDate();
  const currMonth = now.getMonth();
  const currYear = now.getFullYear();
  let loopDate = new Date(currYear, currMonth, 0);
  let data = [];

  const ref = useD3((svg) => {
    function makeGraphic() {
      svg.selectAll("*").remove();
      // set the dimensions and margins of the graph

      var width = 1600,
        height = 820;

      const monthDays = [];
      let loopMonth = currMonth;
      let latestRow = 0;
      while (loopMonth === currMonth) {
        monthDays.push({
          date: loopDate,
          col: loopDate.getDay()===0?6:loopDate.getDay()-1,
          row: latestRow,
        });
        if(loopDate.getDay()===0){latestRow+=1}
        loopDate = new Date(loopDate.getTime() + day);
        loopMonth = loopDate.getMonth();
      }

      if (monthDays[0].date.getDate() > 1) {
        monthDays.shift();
      }
      if (monthDays[0].row > 0) {
        monthDays.forEach((d) => {
          --d.row;
          return d;
        });
      }

      data.push({ currMonth, days: monthDays });
      const calendar = d3.select(".calendar");

      const months = calendar
        .selectAll(".month")
        .data(data)
        .enter()
        .append("div")
        .attr("class", (d) => "month month-" + d.month);

      const svg1 = months.append("svg").attr("viewBox", [0, 0, width, height]);
      const g = svg1
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      const columns = d3.scaleBand().domain(d3.range(0, 7));

      const rows = d3.scaleBand().domain(d3.range(0, 6));

      const days = g
        .selectAll(".day")
        .data((d) => d.days)
        .enter()
        .append("g")
        .attr("class", "day")
        .classed("past", (d) => d.date.getTime() < now.getTime() - currDay)
        .classed(
          "today",
          (d) =>
            d.date.getDate() === now.getDate() &&
            d.date.getMonth() === currMonth &&
            d.date.getFullYear() === currYear
        );
      const dayRects = days.append("rect");

      const dayNums = days
        .append("text")
        .attr("class", "num")
        .text((d) => d.date.getDate())
        .attr("dy", 4.5);

      const dayOfWeek = g
        .selectAll(".day-of-week")
        .data(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"])
        .enter()
        .append("text")
        .attr("class", "day-of-week")
        .attr("dy", -5)
        .text((d) => d);

      redraw();

      function redraw() {
        const margin = { left: 1, right: 1, top: 32, bottom: 1 };

        const box = d3.select(".month").node().getBoundingClientRect();
        const baseWidth = box.width;
        const width = baseWidth - margin.left - margin.right;
        const baseHeight = Math.max(baseWidth / 2, 250);
        const height = baseHeight - margin.top - margin.bottom; // TODO: Figure this out w/r/t aspect ratio

        svg
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom);

        g.attr("transform", "translate(" + [margin.left, margin.top] + ")");

        columns.range([0, width]);

        rows.range([0, height]);

        data.forEach((datum) => {
          datum.days.forEach((d) => {
            d.x0 = columns(d.col);
            d.x1 = d.x0 + columns.bandwidth();
            d.y0 = rows(d.row);
            d.y1 = d.y0 + rows.bandwidth();
            d.v0 = [d.x0, d.y0];

            return d;
          });

          return datum;
        });

        dayOfWeek.attr("x", (d, i) => columns(i) + columns.bandwidth() / 2);

        days.attr("transform", (d) => `translate(${d.v0})`);

        dayRects
          .attr("width", columns.bandwidth())
          .attr("height", rows.bandwidth());

        dayNums
          .attr("x", columns.bandwidth() / 2)
          .attr("y", rows.bandwidth() / 2);
      }
    }
    makeGraphic();
  }, []);

  return (
    <Row className="justify-content-center d-block">
      <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
        <Card>
          <div className="calendar"></div>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = function (state) {
  return {
    petData: state.petData,
  };
};

export default connect(mapStateToProps)(App);
