import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import UpdateVisitModal from "./UpdateVisitModal";
import DeleteVisitModal from "./DeleteVisitModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faPenToSquare,
  faCalendar,
  faClock,
  faWeightScale,
  faFileCircleQuestion
} from "@fortawesome/free-solid-svg-icons";

const VisitInfo = ({
  data,
  attribute,
  selected,
  openModalUpdateVisit,
  setOpenModalUpdateVisit,
  openModalDeleteVisit,
  setOpenModalDeleteVisit,
  ...props
}) => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Athens",
  };

  const visit = data.filter((line) => line[attribute] === selected)[0];

  return (
    <>
      {setOpenModalUpdateVisit && (
        <Row className="justify-content-start d-flex">
          <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
            <Button
              className="me-2"
              onClick={() => setOpenModalUpdateVisit(true)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />{" "}Επεξεργασία Επίσκεψης
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModalDeleteVisit(true)}>
              <FontAwesomeIcon icon={faTrashCan} />{" "}Διαγραφή Επίσκεψης
            </Button>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light">
              <h5 className="mb-0">Πληροφορίες Επίσκεψης</h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4} xs={12}>
                  <b><FontAwesomeIcon icon={faFileCircleQuestion} /></b>{" "}{visit.reason}
                  <br />
                  <b><FontAwesomeIcon icon={faCalendar} /></b>{" "}
                  {new Intl.DateTimeFormat("el-GR", dateOptions).format(
                    new Date(visit.timestamp)
                  )}
                  <br />
                  <b><FontAwesomeIcon icon={faClock} /></b>{" "}
                  {new Intl.DateTimeFormat("el", timeOptions).format(
                    new Date(visit.timestamp)
                  )}
                  <br />
                  <b><FontAwesomeIcon icon={faWeightScale} /></b>{" "}{visit.weight} kg
                </Col>
                <Col md={8} xs={12}>
                  <b>Κλινικά ευρήματα:</b> {visit.findings === "" ? "-" :visit.findings}
                  <br />
                  <b>Θεραπεία:</b> {visit.treatment === "" ? "-" :visit.treatment}
                  <br />
                  <b>Εκκρεμότητες:</b> {visit.pending === "" ? "-" :visit.pending}
                  <br />
                  
                  <Form action="/visits/savecomment" method="post">
                    <input
                      type="text"
                      name="visitID"
                      id="visitID"
                      value={visit.visitID}
                      style={{ display: "none" }}
                      required
                    />
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        name="visitComment"
                        id="visitComment"
                        defaultValue={visit.visitComment}
                        required
                      />
                      <Button variant="primary" type="submit">
                        Αποθήκευση
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UpdateVisitModal
        show={openModalUpdateVisit}
        visit={visit}
        handleClose={() => setOpenModalUpdateVisit(false)}
      />
      <DeleteVisitModal
        show={openModalDeleteVisit}
        visit={visit}
        handleClose={() => setOpenModalDeleteVisit(false)}
      />
    </>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(VisitInfo);
