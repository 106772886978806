export const AppRoutes = {
  // pages
  Calendar: { path: "/" },
  Login: { path: "/login" },
  Owners: { path: "/owners" },
  Pets: { path: "/pets" },
  Visits: { path: "/visits" },
  // NotFound: { path: "/examples/404" },
  // ServerError: { path: "/examples/500" },
};
