import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, FormLabel, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSignature,
  faPaw,
  faMicrochip,
  faVenusMars,
  faNeuter,
  faUser,
  faBed,
  faPills,
  faCakeCandles,
} from "@fortawesome/free-solid-svg-icons";

const RegisterPetModal = ({ show, owner, handleClose, ...props }) => {
  function makeOwnersList() {
    const result = [];
    props.ownerData.forEach((owner) => {
      result.push(<option value={owner.ownerID}>{owner.surname} {owner.name}</option>);
    });
    return result;
  }
  return (
    <Modal size="lg" as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Καταχώρηση κατοικιδίου</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/pets/register" method="post">
          <Row>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="petName">
                <FontAwesomeIcon icon={faSignature} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="petName"
                placeholder="Όνομα Κατοικιδίου"
                id="petName"
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="ownerID">
                <FontAwesomeIcon icon={faUser} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              {owner && (
                <select name="ownerID" id="ownerID" defaultValue={owner.ownerID}>
                  {makeOwnersList()}
                </select>
              )}
              {!owner && (
                <select name="ownerID" id="ownerID">
                  {makeOwnersList()}
                </select>
              )}
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="species">
                <FontAwesomeIcon icon={faPaw} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="species"
                placeholder="Είδος"
                id="species"
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="breed">
                <FontAwesomeIcon icon={faPaw} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="breed"
                placeholder="Φυλή"
                id="breed"
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="birthday">
                <FontAwesomeIcon icon={faCakeCandles} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input type="date" name="birthday" id="birthday" />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="chipNum">
                <FontAwesomeIcon icon={faMicrochip} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="chipNum"
                placeholder="Αριθμός Microchip"
                id="chipNum"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="gender">
                <FontAwesomeIcon icon={faVenusMars} />
              </FormLabel>
            </Col>
            <Col md={3} xs={10} className="ps-0">
              <select name="gender" id="gender">
                <option value="1">Αρσενικό</option>
                <option value="0">Θηλυκό</option>
              </select>
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="neutered">
                <FontAwesomeIcon icon={faNeuter} />
              </FormLabel>
            </Col>
            <Col md={1} xs={10} className="ps-0">
              <input
                type="checkbox"
                name="neutered"
                placeholder="Στειρωμένο"
                id="neutered"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="livingCond">
                <FontAwesomeIcon icon={faBed} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="livingCond"
                placeholder="Συνθήκες Διαβίωσης"
                id="livingCond"
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="preventiveMeas">
                <FontAwesomeIcon icon={faPills} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="preventiveMeas"
                placeholder="Μέτρα Πρόληψης"
                id="preventiveMeas"
              />
            </Col>
            <input type="submit" value="Καταχώρηση" />
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {
    ownerData: state.ownerData,
  };
};

export default connect(mapStateToProps)(RegisterPetModal);
