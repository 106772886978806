import React from "react";
import { connect } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";

const DeleteVisitModal = ({ show, visit, handleClose, ...props }) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Europe/Athens",
    timeZoneName: "short",
  };
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          Είστε σίγουροι ότι θέλετε να διαγράψετε την επίσκεψη για{" "}
          {visit.reason} την{" "}
          {new Intl.DateTimeFormat("el-GR", options).format(
            new Date(visit.timestamp)
          )}
          ;
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/visits/delete" method="post">
          <input
            type="text"
            name="visitID"
            id="visitID"
            value={visit.visitID}
            style={{ display: "none" }}
            required
          />
          <input type="submit" value="ΜΟΝΙΜΗ διαγραφή επίσκεψης" />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(DeleteVisitModal);
