import React from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import UpdateOwnerModal from "./UpdateOwnerModal";
import DeleteOwnerModal from "./DeleteOwnerModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare, faPhone, faEnvelope, faMapLocationDot, faCommentDots } from "@fortawesome/free-solid-svg-icons";

const OwnerInfo = ({
  data,
  attribute,
  selected,
  openModalUpdateOwner,
  setOpenModalUpdateOwner,
  openModalDeleteOwner,
  setOpenModalDeleteOwner,
  ...props
}) => {
  const owner = data.filter((line) => line[attribute] === selected)[0];
  return (
    <>
      {setOpenModalUpdateOwner && (
        <Row className="justify-content-start d-flex">
          <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
            <Button
              className="me-2"
              onClick={() => setOpenModalUpdateOwner(true)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />{" "}Επεξεργασία Ιδιοκτήτη
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModalDeleteOwner(true)}>
              <FontAwesomeIcon icon={faTrashCan} />{" "}Διαγραφή Ιδιοκτήτη
            </Button>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center d-block">
        <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light">
              <h5 className="mb-0">
                {owner.name} {owner.surname}
              </h5>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4} xs={12}>
                  <b> <FontAwesomeIcon icon={faPhone} /></b>{" "}<a href={`tel:${owner.phone}`}>{owner.phone}</a>
                  <br />
                  <b> <FontAwesomeIcon icon={faEnvelope} /></b>{" "}
                  <a
                    href={`mailto:${owner.email}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {owner.email}
                  </a>
                  <br />
                  <b><FontAwesomeIcon icon={faMapLocationDot} /></b>{" "}{owner.address}
                </Col>
                <Col md={8} xs={12}>
                  {attribute !== "ownerID" && (
                    <>
                      <b><FontAwesomeIcon icon={faCommentDots} /></b>{" "}
                      {owner.ownerComment}
                    </>
                  )}
                  {attribute === "ownerID" && (
                    <>
                      <Form action="/owners/savecomment" method="post">
                        <input
                          type="text"
                          name="id"
                          placeholder="Owner's ID"
                          id="id"
                          value={owner.ownerID}
                          style={{ display: "none" }}
                          required
                        />
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            name="ownerComment"
                            id="ownerComment"
                            defaultValue={owner.ownerComment}
                            required
                          />
                          <Button variant="primary" type="submit">
                            Αποθήκευση
                          </Button>
                        </InputGroup>
                      </Form>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UpdateOwnerModal
        show={openModalUpdateOwner}
        owner={owner}
        handleClose={() => setOpenModalUpdateOwner(false)}
      />
      <DeleteOwnerModal
        show={openModalDeleteOwner}
        owner={owner}
        handleClose={() => setOpenModalDeleteOwner(false)}
      />
    </>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(OwnerInfo);
