import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PetsTable from "../components/Pets/PetsTable";

import store from "../store";

const Pets = (props) => {
  const [selectedPet, setSelectedPet] = useState(null);

  useEffect(() => {
    if(props.searchTarget) setSelectedPet(props.searchTarget);
    store.dispatch({
      type: "SET_SEARCH_TARGET",
      payload: null,
    });
  })


  return (
    <>
      <PetsTable
        selectedOwner={null}
        selectedPet={selectedPet}
        setSelectedPet={setSelectedPet}
      />
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    petData: state.petData,
    visitData: state.visitData,
    searchTarget: state.searchTarget,

  };
};

export default connect(mapStateToProps)(Pets);
