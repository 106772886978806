import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Form, FormLabel, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSignature,
  faPaw,
  faCakeCandles,
  faMicrochip,
  faVenusMars,
  faNeuter,
  faPills,
  faBed,
  faSkull
} from "@fortawesome/free-solid-svg-icons";

const UpdatePetModal = ({ show, pet, handleClose, ...props }) => {
  return (
    <Modal size="lg" as={Modal.Dialog} centered show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Ενημέρωση Κατοικιδίου</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="login">
        <Form action="/pets/update" method="post">
          <input
            type="text"
            name="petID"
            id="petID"
            value={pet.petID}
            style={{ display: "none" }}
            required
          />
          <Row>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="petName">
                <FontAwesomeIcon icon={faSignature} />
              </FormLabel>
            </Col>
            <Col md={9} xs={10} className="ps-0">
              <input
                type="text"
                name="petName"
                placeholder="Όνομα Κατοικιδίου"
                id="petName"
                defaultValue={pet.petName}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="deceased">
                <FontAwesomeIcon icon={faSkull} />
              </FormLabel>
            </Col>
            <Col md={1} xs={10} className="ps-0">
              <input
                type="checkbox"
                name="deceased"
                placeholder="Νεκρό"
                id="deceased"
                defaultChecked={pet.deceased}
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="species">
                <FontAwesomeIcon icon={faPaw} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="species"
                placeholder="Είδος"
                id="species"
                defaultValue={pet.species}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="breed">
                <FontAwesomeIcon icon={faPaw} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="breed"
                placeholder="Φυλή"
                id="breed"
                defaultValue={pet.breed}
                required
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="birthday">
                <FontAwesomeIcon icon={faCakeCandles} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="date"
                name="birthday"
                id="birthday"
                defaultValue={
                  new Date(new Date(pet.birthday).getTime() - (new Date(pet.birthday).getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]
                }
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="chipNum">
                <FontAwesomeIcon icon={faMicrochip} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="chipNum"
                placeholder="Αριθμός Microchip"
                id="chipNum"
                defaultValue={pet.chipNum}
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="gender">
                <FontAwesomeIcon icon={faVenusMars} />
              </FormLabel>
            </Col>
            <Col md={3} xs={10} className="ps-0">
              <select name="gender" id="gender" defaultValue={pet.gender}>
                <option value="1">Αρσενικό</option>
                <option value="0">Θηλυκό</option>
              </select>
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="neutered">
                <FontAwesomeIcon icon={faNeuter} />
              </FormLabel>
            </Col>
            <Col md={1} xs={10} className="ps-0">
              <input
                type="checkbox"
                name="neutered"
                placeholder="Στειρωμένο"
                id="neutered"
                defaultChecked={pet.neutered}
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="livingCond">
                <FontAwesomeIcon icon={faBed} />
              </FormLabel>
            </Col>
            <Col md={5} xs={10} className="ps-0">
              <input
                type="text"
                name="livingCond"
                placeholder="Συνθήκες Διαβίωσης"
                id="livingCond"
                defaultValue={pet.livingCond}
              />
            </Col>
            <Col md={1} xs={2} className="p-0">
              <FormLabel htmlFor="preventiveMeas">
                <FontAwesomeIcon icon={faPills} />
              </FormLabel>
            </Col>
            <Col md={11} xs={10} className="ps-0">
              <input
                type="text"
                name="preventiveMeas"
                placeholder="Μέτρα Πρόληψης"
                id="preventiveMeas"
                defaultValue={pet.preventiveMeas}
              />
            </Col>
            <input type="submit" value="Ενημέρωση" />
            </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(UpdatePetModal);
