import React from "react";
import { connect } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { faLeftLong } from "@fortawesome/free-solid-svg-icons";

const BackToTables = ({ flavor, setters, backTo,...props }) => {
  const navigate = useNavigate();

  return (
    <Row className="justify-content-center d-block">
      <Col xs={12} className="mt-1 mb-1 mt-lg-2 mb-lg-4">
        <Card
          border="light"
          className="shadow-sm cursorpointer"
          onClick={() => {
            if(backTo) navigate(backTo);
            setters.forEach((setter) => {
              setter(null);
            });
          }}
        >
          <Card.Body>
            <FontAwesomeIcon icon={faLeftLong} /> Επιστροφή {flavor}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(BackToTables);
